import WemoloLogo from '@assets/wemolo-logo.svg'
import { styled } from 'parkdepot-shared/theme'
import React from 'react'

const HeaderContainer = styled('div', ({ $theme }) => ({
    display: 'flex',
    paddingLeft: $theme.sizing.scale600,
    alignItems: 'center',
    height: '60px'
}))

const Header = () => {
    return (
        <HeaderContainer>
            <WemoloLogo />
        </HeaderContainer>
    )
}

export default Header
