import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND, BUTTON_SHAPE, BUTTON_SIZE } from 'parkdepot-shared/components/Buttons/types'
import { useStyletron } from 'parkdepot-shared/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useHistory } from 'react-router-dom'
import Frame from './Frame'
import DoorPin from './views/DoorPin'
import Entry from './views/Entry'
import LicensePlateNotFound from './views/LicensePlateNotFound'
import MobileNumber from './views/MobileNumber'
import SiteId from './views/SiteId'
import Sms from './views/Sms'
import Error from './views/Error'

const ComponentNotFound = () => {
    const { t } = useTranslation('error')
    const history = useHistory()
    const [css, theme] = useStyletron()

    return (
        <div className={css({ display: 'flex', flexDirection: 'column', gap: theme.sizing.scale800 })}>
            <p>404 not found</p>

            <Button
                $shape={BUTTON_SHAPE.pill}
                $kind={BUTTON_KIND.primary}
                $size={BUTTON_SIZE.medium}
                onClick={() => history.replace('/')}
            >
                {t('goMainPage')}
            </Button>
        </div>
    )
}

const App = () => {
    return (
        <Frame>
            <Switch>
                <Route exact path="/" component={SiteId} />
                <Route exact path="/:siteId([0-9]+)" component={Entry} />
                <Route exact path="/:siteId([0-9]+)/pin" component={DoorPin} />
                <Route exact path="/:siteId([0-9]+)/not-found" component={LicensePlateNotFound} />
                <Route exact path="/:siteId([0-9]+)/mobile-number" component={MobileNumber} />
                <Route exact path="/:siteId([0-9]+)/sms" component={Sms} />
                <Route exact path="/error" component={Error} />
                <Route path="*" component={ComponentNotFound} />
            </Switch>
        </Frame>
    )
}

export default App
