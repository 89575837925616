import React from 'react'
import { styled } from 'baseui'
import PageTitle from '@components/PageTitle'
import ErrorSign from '@assets/cross-circle.svg'
import Button from 'parkdepot-shared/components/Buttons/Button'
import { useTranslation } from 'react-i18next'
import { BUTTON_KIND, BUTTON_SHAPE, BUTTON_SIZE } from 'parkdepot-shared/components/Buttons/types'
import { useHistory } from 'react-router-dom'

const PageContainer = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: $theme.sizing.scale900
}))

const MainContentContainer = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: $theme.sizing.scale1600,
    alignItems: 'center',
    textAlign: 'center'
}))

const LogoContainer = styled('div', () => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
}))

const Error = () => {
    const { t } = useTranslation(['serverErrors'])
    const history = useHistory()

    const onButtonClick = () => {
        history.push('/')
    }

    return (
        <PageContainer>
            <LogoContainer>
                <div>
                    <ErrorSign />
                </div>
            </LogoContainer>
            <PageTitle>{t('tryAgainTitle')}</PageTitle>
            <MainContentContainer>
                <div>{t('tryAgainMessage')}</div>
                <Button
                    $shape={BUTTON_SHAPE.pill}
                    $kind={BUTTON_KIND.primary}
                    $size={BUTTON_SIZE.medium}
                    overrides={{ BaseButton: { style: { width: '100%' } } }}
                    onClick={onButtonClick}
                >
                    {t('common.exit')}
                </Button>
            </MainContentContainer>
        </PageContainer>
    )
}

export default Error
